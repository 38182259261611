import React from 'react';
import { Page, LicenseTable, Section, Block, Header } from 'src/components';

const Lic = ({ label, number }) => (
  <span className="o75 l">
    {' '}
    (<span>{label}</span> {number})
  </span>
);

export default function Licenses() {
  return (
    <Page title="Licenses" path="licenses">
      <Header narrow title="Regulatory & Licensing" bg="" />
      <Section narrow raw>
        <b2>Catch no longer offers banking & ACH services, or investment advisory services.</b2>

        <Block
          light
          w={758}
          small
          smallBody
          title="Insurance Services"
          subtitle="Catch Insurance, LLC"
        >
          <b2>
            Insurance services are provided by Catch Insurance, LLC
            <Lic label="NPN" number="18986211" />
            <br />
            <span className="fn o35">
              Catch Insurance does business as "Catch Insurance Agency, LLC" in CA, IL, MI, and NY.
            </span>
          </b2>
          <br />
          <b2>
            Catch Insurance is licensed to sell QHPs under the Federally-Facilitated Marketplace as
            a Web-Broker and Primary EDE Entity <Lic label="NPN" number="20655395" />
          </b2>
          <br />
          <b2>
            State licensing information is provided below.
            <br />
            Expand any state for more information about individual licenses
          </b2>
        </Block>

        <LicenseTable />
      </Section>
    </Page>
  );
}
